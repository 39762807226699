<template>
  <div class="projet-box">

    <div class="project-page" v-if="currentProject">
      <h1 class="project-name">{{currentProject.name}}</h1>
      <ul class="talent-jobs">
        <li v-for="domain of domains">{{domain}}</li>
      </ul>
      <p class="project-description">{{currentProject.description}}</p>
      <div class="project-medias">
        <hr>
        <template v-for="media of currentProject.Medias">
          <media :media="media"></media>
        </template>
      </div>
    </div>
    <ul class="bottomNavTools">
      <li>
        <back-button></back-button>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import backButton from '@/components/BackButton'
import TalentTools from "@/components/TalentTools";
import store from "@/store";

const setCurrentTalentByRoute = async function (route) {
  if (route.query.talent) {
    let talent = JSON.parse(decodeURIComponent(route.query.talent))
    await store.dispatch('SET_CURRENT_TALENT', talent)
  } else {
    await store.dispatch('SET_CURRENT_TALENT_BY_NAME', route.params.name)
  }
}
const setCurrentProjectByRoute = async function (route) {
  if (route.query.talent) {
    let talent = JSON.parse(decodeURIComponent(route.query.talent))
    await store.dispatch('SET_CURRENT_TALENT', talent)
  } else {
    await store.dispatch('SET_CURRENT_PROJECT_BY_NAME', decodeURIComponent(route.params.slug))
  }
}

export default {
  name: 'projet',
  data() {
    return {}
  },
  components: {
    backButton,
    TalentTools
  },
  computed: {
    ...mapState(['currentProject']),
    domains() {
      return this.currentProject.DomainsList.split(',')
    },
  },
  async beforeRouteUpdate(to, from, next) {
    await setCurrentTalentByRoute(to)
    await setCurrentProjectByRoute(to)
    next()
  },
  async beforeRouteEnter(to, from, next) {
    await setCurrentTalentByRoute(to)
    await setCurrentProjectByRoute(to)
    next()
  },
  methods: {
    getMediaData(media) {
      return {
        src: `http://static.drimscreative.com${media.path}`,
        width: media.width,
        height: media.height,
      }
    },
  },
}
</script>
<style lang="scss">
@import "~@/assets/styles/_lists.scss";

.projet-box {

  .project-name {
    font-size: 45px;
    font-weight: 400;
  }

  .project-domains {
    @extend .inline-list;
    @extend .flex-center;
    font-size: 20px;
    font-weight: 600;

    .project-domain {
      margin: 0;

      &:after {
        content: '|';
        margin: 0 20px;
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }

  .project-description {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 60%;
    margin: 50px auto 30px;
  }

  .project-medias {
    transform: translateY(-10px);
    .media-box {
      text-align: center;

      iframe {
        min-height: 700px;
        min-width: 100%;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }

  .bottomNavTools {
    margin: 0;
  }
}
</style>
