<template>
  <div class="talent-box" v-if="isLoaded">
    <template v-if="currentTalent">
      <component :is="profilComponent" v-if="profilComponent" :talent="currentTalent"></component>
    </template>
    <template v-else>
      <loader></loader>
    </template>
    <ul class="bottomNavTools">
      <li>
        <back-button></back-button>
      </li>
    </ul>
    <logo></logo>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import store from "@/store";
import {mapState} from 'vuex'
import BackButton from "../components/BackButton";

const setCurrentTalentByRoute = async function (route) {
  if (route.query.preview) {
    console.log('query')
    await store.dispatch('SET_CURRENT_TALENT_BY_NAME', {
      preview: true,
      talent: route.params.name
    })
  } else {
    await store.dispatch('SET_CURRENT_TALENT_BY_NAME', route.params.name)
  }
}

export default {
  name: 'talent',
  components: {BackButton, Loader},
  metaInfo (vm) {
    return {
      title: `${vm.currentTalent ? vm.currentTalent.displayName : ''}`,
      titleTemplate: '%s - DRiMSCREATiVE ',
      meta: [
        {
          name: "description",
          content: "Agents de Freelances, Agent de Talents"
        }
      ]
    }
  },
  data () {
    return {
      isLoaded: true
    }
  },
  computed: {
    ...mapState(['currentTalent', 'talents', 'device', 'filteredTalents', 'filteredTalentsIndex']),
    profilComponent () {
      if (!this.currentTalent.profilType) {
        return false
      }
      switch (this.currentTalent.profilType) {
        case  'CREA':
          return require('@/components/talentCreative.vue').default
        case 'DEV':
          return require('@/components/talentDeveloper.vue').default
        case 'GES':
          return require('@/components/talentManager.vue').default
      }
      return null
    },
  },
  methods: {
    async goToPreviousTalent () {
      let index = this.filteredTalentsIndex
      let previousTalent = await this.$store.dispatch('GET_CURRENT_TALENT_BY_INDEX', index - 1)
      this.$nextTick(() => {
        this.$router.push({name: 'talent', params: {name: previousTalent.screenName}})
      })
    },
    async goToNextTalent () {
      let index = this.filteredTalentsIndex
      let nextTalent = await this.$store.dispatch('GET_CURRENT_TALENT_BY_INDEX', index + 1)
      this.$nextTick(() => {
        this.$router.push({name: 'talent', params: {name: nextTalent.screenName}})
      })
    }
  },
  async beforeRouteUpdate (to, from, next) {
    await setCurrentTalentByRoute(to)
    next()
  },
  async beforeRouteEnter (to, from, next) {
    await setCurrentTalentByRoute(to)
    next()
  },
  watch: {
    currentTalent: {
      immediate: true,
      async handler () {
        // setCurrentTalentByRoute(this.$route)
      }
    }
  }
}
</script>
<style lang="scss">
.talent-box {
  text-align: center;

  .logo {
    margin-top: 140px;
  }
}

.talent-page {
  h1 {
    font-size: 45px;
    font-weight: 400;
    margin: 0 0 20px;
    text-transform: initial;
  }
}

.talent-jobs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 50%;
  margin: 30px auto 80px;

  li {
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;
    border-radius: 100px;
    border: 1px solid black;


    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 400px;
    color: black;
    white-space: nowrap;
  }
}

.is-mobile, .is-tablet {
  .talent-page h1 {
    margin-bottom: 10px;
  }

  .talent-jobs {
    max-width: none;
  }

  .talent-jobs li:after {
    margin: 0 5px;
  }
}

.talent-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  h1 {

  }
}

.talent-project {
  img {
    max-width: 100%;
    height: auto;
  }
}


.bottomNavTools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 140px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    a {
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  li:not(:last-of-type):after {
    content: '|';
    margin: 0 1em;
  }

  /*&:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 5px;
  }

  &:after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    -webkit-transform: rotate(45deg);
    transform: rotate(-135deg);
    margin: 0 5px;
  }*/
}
</style>
