<template>
  <div :data-id="talent.uuid" class="talent-page talent-developer">
    <h1>{{ talent.displayName }}</h1>
    <ul class="talent-jobs">
      <li v-for="job of talent.tagsTexts">{{ job }}</li>
    </ul>
    <div class="long-text">
      <h2>Compétences</h2>
      <div v-if="talent.languages" class="cv">
        <h3>Langages et Normes</h3>
        <div v-html="sanitizeUserInput(talent.language)"></div>
      </div>
      <div v-if="talent.tools" class="cv">
        <h3>Navigateurs et outils</h3>
        <div v-html="sanitizeUserInput(talent.tools)"></div>
      </div>
      <div v-if="talent.probs" class="cv">
        <h3>Problématiques Métiers</h3>
        <div v-html="sanitizeUserInput(talent.probs)"></div>
      </div>
      <div v-if="talent.fw" class="cv">
        <h3>Framework et CMS</h3>
        <div v-html="sanitizeUserInput(talent.fw)"></div>
      </div>
      <template v-if="talent.xps && talent.xps.length">
        <h2>Expériences</h2>
        <div class="cv">
          <template v-for="xp of talent.xps">
            <h3>
              <span v-if="xp.poste" class="developer-poste">{{ xp.poste }}</span>
              <span v-if="xp.contrat" class="developer-contrat">({{ xp.contrat }})</span>
              <span v-if="xp.societe" class="developer-firm">{{ xp.societe }}</span>
            </h3>
            <p class="developer-date">
              <span v-html="formatDate(xp.startDateTxt)"></span>
              <span v-html="formatDate(xp.endDateTxt)"></span>
            </p>
            <p v-html="sanitizeUserInput(xp.description)"></p>
          </template>
        </div>
      </template>
      <template v-if="talent.formation && talent.formation.length">
        <h2>Formations</h2>
        <div v-for="formation of talent.formation" class="cv">
          <h3>
            <span v-if="formation.poste" class="developer-poste">{{ formation.poste }}</span>
            <span v-if="formation.societe" class="developer-firm">{{ formation.societe }}</span>
          </h3>
          <span v-html="formatDate(formation.endDate)"></span>
          <div v-html="sanitizeUserInput(formation.description)"></div>
        </div>
      </template>
      <template v-if="talent.lang1">
        <h2>Langues</h2>
        <div class="cv">
          <template v-if="talent.lang1">
            <p>{{ talent.lang1 }}: {{ getLangLevel(talent.lang1level) }}</p>
          </template>
          <template v-if="talent.lang2">
            <p>{{ talent.lang2 }}: {{ getLangLevel(talent.lang2level) }}</p>
          </template>
          <template v-if="talent.lang3">
            <p>{{ talent.lang3 }}: {{ getLangLevel(talent.lang3level) }}</p>
          </template>
        </div>
      </template>
      <template v-if="talent.extra">
        <h2>Extraprofessionnel</h2>
        <div class="cv">
          <p v-html="sanitizeUserInput(talent.extra)"></p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import MonthMapping from "@/assets/json/monthMapping.json";
import ResponsiveGrid from '@/components/responsiveGrid'
import TalentTools from '@/components/TalentTools'
import sleep from '@/helpers/sleep'
import {mapState} from 'vuex'

export default {
  name: 'talentDeveloper',
  components: {TalentTools, ResponsiveGrid},
  data () {
    return {
      showTools: false,
    }
  },
  methods: {
    getImagePath (path) {
      return `http://static.drimscreative.com${path}`
    },
    async goToProjectPage (project) {
      await this.$store.commit('SET_CURRENT_PROJECT', project)
      await this.$router.push({
        name: 'project',
        params: {
          name: this.talent.screenName,
          slug: project.slug,
        },
      })
    },
    getLangLevel (level) {
      if (level === 2) {
        return 'Langue maternelle'
      } else if (level === 1) {
        return 'Bon niveau'
      } else {
        return 'Basique'
      }
    },
    sanitizeUserInput (desc) {
      // remove - at beginning
      desc = desc.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\r|\n/g, '')
          .trim()
      let split = desc.split('<br>')
      split = split.map((s) => s.replace(/^ ?- ?/, ''))
      split = split.join('</li><li>')
      return `<ul><li>${split}</li></ul>`.trim()
    },
    formatDate (date) {
      if (/[a-z]/i.test(date)) {
        let sp = date.split(' ')
        sp = sp.map((item) => {
          item = item.toLowerCase()
          if (MonthMapping[item]) {
            return MonthMapping[item]
          } else {
            return item
          }
        })
        date = sp.join(" ")
      } else {
        let D = new Date(date)
        date = D.getFullYear()
      }
      return date
    },
  },
  computed: {
    ...mapState(['breakpoint']),
    mediasList () {
      const medias = []
      for (let proj of this.talent.projets) {
        medias.push(proj.Medias[0])
      }
      return medias.flat()
    },
  },
  props: {
    talent: {
      type: Object,
      required: true,
    },
  },
  async beforeMount () {
    await sleep()
    this.showTools = true
  },
  async beforeRouteLeave (to, from, next) {
    this.showTools = false
    await sleep(500)
    next()
  },
}
</script>
<style lang="scss">

</style>
