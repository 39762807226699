<template>
  <div :data-id="talent.uuid" class="talent-page talent-creative" :key="talent.uuid" v-if="talent">
    <h1>{{ talent.displayName }}</h1>
    <ul class="talent-jobs">
      <li v-for="job of talent.tagsTexts">{{ job }}</li>
    </ul>

    <hr>

    <responsive-grid :data="talent.projets" :numbers-of-colums="breakpoint.columns">
      <template v-slot:default="data">
        <card
            @click="goToProjectPage(data.talent)"
            :media="data.talent.Medias[0]"
            :title="data.talent.name"
            :text="data.talent.agency"
            class="talent-card"
        ></card>
      </template>
    </responsive-grid>
  </div>
</template>
<script>
import ResponsiveGrid from '@/components/responsiveGrid'
import Card from '@/components/Card'
import TalentTools from '@/components/TalentTools'
import {mapState} from 'vuex'
import sleep from '@/helpers/sleep'
import BackButton from "@/components/BackButton";

export default {
  name: 'talentCreative',
  components: {BackButton, TalentTools, ResponsiveGrid, Card},
  data () {
    return {
      showTools: false,
    }
  },
  methods: {
    getImagePath (path) {
      return `http://static.drimscreative.com${path}`
    },
    async goToProjectPage (project) {
      await this.$store.commit('SET_CURRENT_PROJECT', project)
      await this.$router.push({
        name: 'project',
        params: {
          name: this.talent.screenName,
          slug: project.slug,
        },
      })
    },
  },
  computed: {
    ...mapState(['breakpoint', 'config']),
    mediasList () {
      const medias = []
      for (let proj of this.talent.projets) {
        medias.push(proj.Medias[0])
      }
      return medias.flat()
    },
  },
  props: {
    talent: {
      type: Object,
      required: true,
    },
  },
  async beforeMount () {
    await sleep(500)
    this.showTools = true
  },
  async beforeRouteLeave (to, from, next) {
    this.showTools = false
    await sleep(500)
    next()
  },
}
</script>
<style lang="scss">
.talent-creative {
  .talent-project {
    margin-bottom: 100px;
  }

  .talent-project-name {
    font-size: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .talent-project-desc {
    font-size: 16px;
    font-weight: 400;
  }
}

.talent-card {
  .media-box {
    img {
      max-width: 100%;
      height: auto;
    }

    iframe {
      width: 100%;
    }
  }
}

</style>
